import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard, RoleBasedGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const OverviewAnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
// SEGUROS
const PolizasListPage = lazy(() => import('src/pages/seguros/Polizas'));
const PolizaCreatePage = lazy(() => import('src/pages/seguros/PolizaCreatePage'));
const PolizaEditPage = lazy(() => import('src/pages/seguros/PolizaEditPage'));
const PolizaDetailsPage = lazy(() => import('src/pages/seguros/PolizaDetailsPage'));
const PolizaRenovacionPage = lazy(() => import('src/pages/seguros/PolizaRenovacionPage'));
const PolizaEvaluacionPage = lazy(() => import('src/pages/seguros/PolizaEvaluacionPage'));
const SiniestrosListPage = lazy(() => import('src/pages/seguros/Siniestros'));
const SiniestroCreatePage = lazy(() => import('src/pages/seguros/SiniestroCreatePage'));
const SiniestroEditPage = lazy(() => import('src/pages/seguros/SiniestroEditPage'));
const SiniestroDetailsPage = lazy(() => import('src/pages/seguros/SiniestroDetailsPage'));
const RepositorioListPage = lazy(() => import('src/pages/seguros/RepositorioListPage'));
const SoportePage = lazy(() => import('src/pages/soporte/SoportePage'));
const TareasPage = lazy(() => import('src/pages/seguros/TareasPage'));
const EvaluacionPolizaPage = lazy(() => import('src/pages/seguros/EvaluacionPolizaPage'));
const HerramientasPage = lazy(() => import('src/pages/seguros/HerramientasPage'));
const AnalisisRiesgos = lazy(() => import('src/pages/seguros/AnalisisRiesgoPage'));
const DashboardRiesgos = lazy(() => import('src/pages/seguros/DashboardRiesgoPage'));
const RiesgoCreatePage = lazy(() => import('src/pages/seguros/RiesgoCreatePage'));
const RiesgoDetailsPage = lazy(() => import('src/pages/seguros/RiesgoDetailsPage'));
const RiesgoEditPage = lazy(() => import('src/pages/seguros/RiesgoEditPage'));
const GestionRiesgoPage = lazy(() => import('src/pages/seguros/GestionRiesgoPage'));
const EstadisticasPage = lazy(() => import('src/pages/seguros/EstadisticasPage'));
// USER
const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
const UserCardsPage = lazy(() => import('src/pages/dashboard/user/cards'));
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));
// APP
const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'analytics', element: <OverviewAnalyticsPage /> },
      {
        path: 'seguros',
        children: [
          { element: <PolizasListPage />, index: true },
          { path: 'polizas', element: <PolizasListPage /> },
          { path: 'polizas/new', element: <PolizaCreatePage /> },
          { path: 'polizas/:id/edit', element: <PolizaEditPage /> },
          { path: 'polizas/:id', element: <PolizaDetailsPage /> },
          { path: 'polizas/:id/renovacion', element: <PolizaRenovacionPage /> },
          { path: 'polizas/:id/evaluacion', element: <PolizaEvaluacionPage /> },
          { path: 'siniestros', element: <SiniestrosListPage /> },
          { path: 'siniestro/new', element: <SiniestroCreatePage /> },
          { path: 'siniestro/:id/edit', element: <SiniestroEditPage /> },
          { path: 'siniestro/:id', element: <SiniestroDetailsPage /> },
          { path: 'repositorio', element: <RepositorioListPage /> },
          { path: 'soporte', element: <SoportePage /> },
          { path: 'tareas', element: <TareasPage /> },
          { path: 'evaluacionPoliza', element: <EvaluacionPolizaPage /> },
          { path: 'herramientas', element: <HerramientasPage /> },
          { path: 'analisisRiesgos', element: <AnalisisRiesgos /> },
          { path: 'dashboardRiesgos', element: <DashboardRiesgos /> },
          { path: 'riesgo/new', element: <RiesgoCreatePage /> },
          { path: 'riesgo/:id', element: <RiesgoDetailsPage /> },
          { path: 'riesgo/:id/edit', element: <RiesgoEditPage /> },
          { path: 'riesgo/:id/gestion', element: <GestionRiesgoPage /> },
          { path: 'estadisticas', element: <EstadisticasPage /> },
        ],
      },
      {
        path: 'soporte',
        children: [{ path: 'soporte', element: <SoportePage /> }],
      },
      {
        path: 'user',
        children: [
          { element: <UserAccountPage />, index: true },
          // { path: 'profile', element: <UserProfilePage /> },
          // { path: 'cards', element: <UserCardsPage /> },
          // { path: 'list', element: <UserListPage /> },
          { path: 'new', element:<RoleBasedGuard roles={['administrador']} hasContent><UserCreatePage/></RoleBasedGuard>},
          // { path: ':id/edit', element: <UserEditPage /> },
          { path: 'account', element: <UserAccountPage /> },
        ],
      },
      { path: 'calendar', element: <CalendarPage /> },
    ],
  },
];

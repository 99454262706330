import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import EventIcon from '@mui/icons-material/Event';

const NotificationItem = ({ notification }) => {
  const { nombre, fechaVencimiento } = notification;

  // Función para analizar la cadena de la fecha y extraer solo la parte de la fecha
  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    return dateObj.toISOString().split('T')[0];
  };

  return (
    <ListItem disableGutters>
      <ListItemIcon>
        <EventIcon color="primary" fontSize="small" />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography variant="subtitle2">
            {nombre}
          </Typography>
        }
        secondary={
          <Typography variant="caption" color="textSecondary">
            Vence el: {formatDate(fechaVencimiento)}
          </Typography>
        }
      />
    </ListItem>
  );
};

export default NotificationItem;

// ----------------------------------------------------------------------

export const UBICACION_OPTION = [
  { label: 'Escuadrón', value: 'Escuadrón' },
  { label: 'Mejillones', value: 'Mejillones' },
  { label: 'Puerto Montt', value: 'Puerto Montt' },
  { label: 'Quilicura', value: 'Quilicura' },
  { label: 'Quintero', value: 'Quintero' },
  { label: 'Santa María', value: 'Santa María' },
  { label: 'Otra Locación', value: 'Otra Locación' },
];

export const AREASAFECTADAS_OPTION = [
  { label: 'Administración', value: 'Administración' },
  { label: 'Dilox', value: 'Dilox' },
  { label: 'Finanzas', value: 'Finanzas' },
  { label: 'Ingeniería', value: 'Ingeniería' },
  { label: 'Mantenimiento', value: 'Mantenimiento' },
  { label: 'Minería', value: 'Minería' },
  { label: 'Operaciones', value: 'Operaciones' },
  { label: 'Químicos', value: 'Químicos' },
  { label: 'Resinas', value: 'Resinas' },
  { label: 'Terminales', value: 'Terminales' },
];

export const RAMO_OPTION = [
  { label: 'Riesgo Ambiental (ENV)', value: 'Riesgo Ambiental (ENV)' },
  { label: 'Responsabilidad de Directores (D&O)', value: 'Responsabilidad de Directores (D&O)'},
  { label: 'Responsabilidad de Contratistas (OCIP)', value: 'Responsabilidad de Contratistas (OCIP)' },
  { label: 'Responsabilidad Civil (RC)', value: 'Responsabilidad Civil (RC)' },
  { label: 'Póliza de Transporte (STP)', value: 'Póliza de Transporte (STP)' },
  { label: 'Responsabilidad de Terminal (TOL)', value: 'Responsabilidad de Terminal (TOL)' },
  { label: 'Todo Riesgo Industrial (TRI)', value: 'Todo Riesgo Industrial (TRI)' },
];

export const LIQUIDADORES = [
  { label: 'Addvalora Global Loss', value: 'Crawford Chile' },
  { label: 'Beckett Liquidadores', value: 'Beckett Liquidadores' },
  { label: 'Charles Taylor', value: 'Charles Taylor' },
  { label: 'Crawford Chile', value: 'Crawford Chile' },
  { label: 'Graham Miller', value: 'Graham Miller' },
  { label: 'Probitas Liquidadores', value: 'Probitas Liquidadores' },
  { label: 'SGC Ajustadores', value: 'SGC Ajustadores' },
  { label: 'Viollier y Asociados', value: 'Viollier y Asociados' },
];

export const CLAUSULAS_AFECTADAS = [
  { label: 'RC General', value: 'RC General' },
  { label: 'RC Patronal', value: 'RC Patronal' },
  { label: 'RC Cruzada', value: 'RC Cruzada' },
  { label: 'RC para Empresas Contratistas', value: 'RC para Empresas Contratistas' },
  { label: 'RC de Vehículos', value: 'RC de Vehículos' },
  { label: 'RC de Productos', value: 'RC de Productos' },
  { label: 'RC de Propietario de Inmuebles', value: 'RC de Propietario de Inmuebles' },
  { label: 'RC de Empresas', value: 'RC de Empresas' },
  { label: 'RC de Empresas de la construcción', value: 'RC de Empresas de la construcción' },
  { label: 'RC de Transporte de Carga', value: 'RC de Transporte de Carga' },
  { label: 'RC para Empresas de Transporte', value: 'RC para Empresas de Transporte' },
  { label: 'RC por daños causados a la carga', value: 'RC por daños causados a la carga' },
  { label: 'RC por daños causados por la carga', value: 'RC por daños causados por la carga' },
  {
    label: 'RC para Empresas de Transporte de Pasajeros',
    value: 'RC para Empresas de Transporte de Pasajeros',
  },
  { label: 'RC por Daño Moral', value: 'RC por Daño Moral' },
  { label: 'RC por Lucro Cesante', value: 'RC por Lucro Cesante' },
];

export const AREAS_Ambiental = [
  'Área Afectada',
  'Prevención de Riesgos',
  'Legal',
  'Seguros',
  'Otra Área',
];

export const REQUERIMIENTOS_Ambiental = [
  {
    area: 'Área Afectada',
    requerimiento: [
      {
        requerimiento: 'Reportes e informes sobre el incidente ambiental.',
      },
      {
        requerimiento: 'Copia de la notificación del reclamo recibida.',
      },
      {
        requerimiento: 'Fotografías del lugar del incidente y de cualquier daño causado por el siniestro.',
      },
      {
        requerimiento: 'Facturas y presupuestos de estimación de reparación de daños.',
      },
      {
        requerimiento: 'Respaldo tributario detallado de costos de remediación y monitoreo.',
      },
    ],
  },
  {
    area: 'Prevención de Riesgos',
    requerimiento: [
      {
        requerimiento: 'Planes de mitigación y remediación del daño, y costos estimados.',
      },
      {
        requerimiento: 'Evaluación de la extensión del daño ambiental.',
      },
      {
        requerimiento: 'Reportes de investigación interna del incidente.',
      },
      {
        requerimiento: 'Plan de respuesta a emergencias ambientales.',
      },
      {
        requerimiento: 'Resultados de muestreos después del incidente.',
      },
      {
        requerimiento: 'Documentación de sistemas y protocolos ambientales.',
      },
      {
        requerimiento: 'Evaluación hidrogeológica del impacto del siniestro en aguas subterráneas.',
      },
      {
        requerimiento: 'Registros de capacitación en seguridad asociados al incidente.',
      },
    ],
  },
  {
    area: 'Legal',
    requerimiento: [
      {
        requerimiento:
          'Documentación sobre causas y responsabilidad que podría asistir a la empresa.',
      },
      {
        requerimiento: 'Informe de comunicaciones con autoridades regulatorias.',
      },
      {
        requerimiento: 'Informes de sanciones o multas aplicadas con ocasión del incidente.',
      },
      {
        requerimiento: 'Reclamos de terceros afectados como residentes.',
      },
    ],
  },
  {
    area: 'Seguros',
    requerimiento: [
      {
        requerimiento: 'Estudios de impacto ambiental previos del sitio afectado.',
      },
      {
        requerimiento: 'Informe de evaluación del riesgo ocurrido, realizado antes del incidente.',
      },
    ],
  },
];

export const AREAS_DyO = ['Área Afectada', 'Legal', 'Seguros', 'Otra Área'];

export const REQUERIMIENTOS_DyO = [
  {
    area: 'Área Afectada',
    requerimiento: [
      {
        requerimiento: 'Detalles sobre la demanda o reclamo recibido por el director o ejecutivo.',
      },
      {
        requerimiento: 'Copia de la notificación del reclamo recibida.',
      },
      {
        requerimiento: 'Versión oficial de hechos relativos al reclamo.',
      },
      {
        requerimiento: 'Correspondencia relacionada con la demanda o reclamo.',
      },
    ],
  },
  {
    area: 'Legal',
    requerimiento: [
      {
        requerimiento: 'Documentación corporativa relevante al reclamo, como actas y contratos.',
      },
      {
        requerimiento: 'Políticas corporativas que delinean conductas esperadas en directores.',
      },
      {
        requerimiento: 'Detalles sobre la estrategia de defensa planificada.',
      },
      {
        requerimiento: 'Historial de demandas previas contra directivos.',
      },
      {
        requerimiento: 'Evaluación legal  de los reclamos presentados contra el ejecutivo.',
      },
      {
        requerimiento: 'Presupuesto detallado de costos de representación legal.',
      },
    ],
  },
  {
    area: 'Seguros',
    requerimiento: [
      {
        requerimiento:
          'Informes de Auditorías Internas o Externas realizadas de forma previa al reclamo.',
      },
    ],
  },
];
export const AREAS_PolizaTerceros = [
  'Área Afectada',
  'Prevención de Riesgos',
  'Seguros',
  'Otra Área',
];

export const REQUERIMIENTOS_PolizaTerceros = [
  {
    area: 'Área Afectada',
    requerimiento: [
      {
        requerimiento: 'Detalles de reclamo recibido por la empresa.',
      },
      {
        requerimiento: 'Copia del reclamo o notificación recibida.',
      },
      {
        requerimiento: 'Facturas y presupuestos de estimación por reparación de daños.        ',
      },
      {
        requerimiento: 'Fotografías del lugar del incidente y de cualquier daño causado.        ',
      },
    ],
  },
  {
    area: 'Prevención de Riesgos',
    requerimiento: [
      {
        requerimiento:
          'Historial de seguridad y procedimientos relevantes al contratista involucrado.',
      },
      {
        requerimiento:
          'Reportes internos evacuados para determinar la causa y el alcance del daño.',
      },
      {
        requerimiento: 'Registros de seguridad asociados al contratista.',
      },
      {
        requerimiento: 'Historial de capacitación de los colaboradores involucrados.',
      },
      {
        requerimiento: 'Reportes de investigación interna del incidente.',
      },
      {
        requerimiento: 'Acciones tomadas para prevenir o mitigar mayores daños.',
      },
    ],
  },
  {
    area: 'Seguros',
    requerimiento: [
      {
        requerimiento: 'Informes realizados al contratista, de forma previa al reclamo.',
      },
    ],
  },
];

export const AREAS_RC = [
  'Área Afectada',
  'Prevención de Riesgos',
  'RR.HH.',
  'Seguros',
  'Otra Área',
];

export const REQUERIMIENTOS_RC = [
  {
    area: 'Área Afectada',
    requerimiento: [
      {
        requerimiento:
          'Descripción del siniestro, con especial mención de sus causas y consecuencias.',
      },
      {
        requerimiento: 'Copia de la notificación del reclamo recibida.',
      },
      {
        requerimiento:
          'Fotografías del lugar del incidente y de cualquier daño causado por el siniestro.',
      },
      {
        requerimiento: 'Facturas y presupuestos de estimación de reparación de daños.',
      },
      {
        requerimiento: 'Correspondencia completa relacionada al reclamante.',
      },
    ],
  },
  {
    area: 'Prevención de Riesgos',
    requerimiento: [
      {
        requerimiento: 'Historial de seguridad y procedimientos relevantes al incidente.',
      },
      {
        requerimiento: 'Historial de capacitación de los colaboradores involucrados.',
      },
      {
        requerimiento: 'Reportes de investigación interna del incidente.',
      },
      {
        requerimiento: 'Registros de capacitación en seguridad asociados al incidente.',
      },
    ],
  },
  {
    area: 'RR.HH.',
    requerimiento: [
      {
        requerimiento: 'Contrato de trabajo del colaborador afectado en el accidente',
      },
      {
        requerimiento: 'Detalles de lesiones o daños causados a terceros.',
      },
      {
        requerimiento: 'Registros médicos si hubo lesiones, para evaluar la gravedad y causalidad.',
      },
    ],
  },
  {
    area: 'Seguros',
    requerimiento: [
      {
        requerimiento: 'Evaluación de la responsabilidad potencial que le asiste a la empresa.',
      },
      {
        requerimiento: 'Informe de evaluación del riesgo ocurrido, realizado antes del incidente.',
      },
    ],
  },
];

export const AREAS_RC_CONTRATISTAS = [
  'Área Afectada',
  'Prevención de Riesgos',
  'RR.HH',
  'Otra Área',
];

export const REQUERIMIENTOS_RC_CONTRATISTAS = [
  {
    area: 'Área Afectada',
    requerimiento: [
      {
        requerimiento:
          'Descripción del siniestro, con especial mención de sus causas y consecuencias.',
      },
      {
        requerimiento: 'Contrato de trabajo del colaborador afectado en el accidente.',
      },
      {
        requerimiento: 'Reclamos recibidos con ocasión del accidente.',
      },
      {
        requerimiento: 'Documentos que acrediten la propiedad de los bienes afectados.',
      },
      {
        requerimiento: 'Documentación tributaria que respalde los daños y su valoración.',
      },
      {
        requerimiento: 'Cotizaciones de reparación y/o reemplazo de bienes dañados.',
      },
      {
        requerimiento: 'Registros de mantenimiento de los bienes afectados por el siniestro.',
      },
      {
        requerimiento:
          'Fotografías del lugar del incidente y de cualquier daño causado por el siniestro.',
      },
      {
        requerimiento: 'Presupuestos detallados de gastos de mitigación de pérdidas.',
      },
      {
        requerimiento: 'Facturas y presupuestos de estimación de reparación de daños.',
      },
    ],
  },
  {
    area: 'Prevención de Riesgos',
    requerimiento: [
      {
        requerimiento: 'Registros de seguridad CCTV que puedan haber registrado el incidente.',
      },
      {
        requerimiento:
          'Historial de seguridad y procedimientos relevantes al contratista involucrado.',
      },
      {
        requerimiento: 'Reporte policial, si aplica.',
      },
      {
        requerimiento: 'Reporte de bomberos, si aplica.',
      },
      {
        requerimiento:
          'Reportes internos evacuados para determinar la causa y el alcance del daño.',
      },
    ],
  },
  {
    area: 'RR.HH',
    requerimiento: [
      {
        requerimiento: 'Contrato de trabajo del colaborador afectado en el accidente.',
      },
      {
        requerimiento: 'Detalles de lesiones o daños causados a terceros.',
      },
      {
        requerimiento: 'Registros médicos si hubo lesiones, para evaluar la gravedad y causalidad.',
      },
    ],
  },
];

export const AREAS_STP = ['Área Afectada', 'Prevención de Riesgos', 'Legal', 'Otra Área'];

export const REQUERIMIENTOS_STP = [
  {
    area: 'Área Afectada',
    requerimiento: [
      {
        requerimiento: 'Descripción del vehículo involucrado y circunstancias del incidente.',
      },
      {
        requerimiento: 'Documentos que evidencien propiedad y valor de carga. ',
      },
      {
        requerimiento: 'Reclamos recibidos con ocasión del incidente.',
      },
      {
        requerimiento: 'Reporte policial, si aplica.',
      },
      {
        requerimiento: 'Itinerarios y rutas del vehículo involucrado.',
      },
      {
        requerimiento: 'Reportes de uso y kilometraje del vehículo involucrado.',
      },
      {
        requerimiento: 'Historial de inspecciones y mantenimiento regulares.',
      },
      {
        requerimiento: 'Licencia de conducir del colaborador involucrado.',
      },
      {
        requerimiento: 'Copia de la revisión técnica del vehículo involucrado.',
      },
    ],
  },
  {
    area: 'Prevención de Riesgos',
    requerimiento: [
      {
        requerimiento: 'Licencias de conducir e historial de capacitación.',
      },
      {
        requerimiento: 'Documentación de capacitación técnica especializada del conductor.',
      },
      {
        requerimiento: 'Reporte de telemetría o GPS del equipo, al día del incidente.',
      },
    ],
  },
  {
    area: 'Legal',
    requerimiento: [
      {
        requerimiento: 'Factura o contrato de compra de la carga involucrada.',
      },
    ],
  },
];

export const AREAS_TOL = [
  'Área Afectada',
  'Prevención de Riesgos',
  'RR.HH',
  'Seguros',
  'Otra Área',
];

export const REQUERIMIENTOS_TOL = [
  {
    area: 'Área Afectada',
    requerimiento: [
      {
        requerimiento:
          'Descripción del siniestro, con especial mención de sus causas y consecuencias.',
      },
      {
        requerimiento: 'Copia de la notificación del reclamo recibida.',
      },
      {
        requerimiento:
          'Fotografías del lugar del incidente y de cualquier daño causado por el siniestro.',
      },
      {
        requerimiento: 'Facturas y presupuestos de estimación de reparación de daños.',
      },
      {
        requerimiento: 'Correspondencia completa relacionada al reclamante.',
      },
    ],
  },
  {
    area: 'Prevención de Riesgos',
    requerimiento: [
      {
        requerimiento: 'Historial de seguridad y procedimientos relevantes al incidente.',
      },
      {
        requerimiento: 'Historial de capacitación de los colaboradores involucrados.',
      },
      {
        requerimiento: 'Reportes de investigación interna del incidente.',
      },
      {
        requerimiento: 'Registros de capacitación en seguridad asociados al incidente.',
      },
    ],
  },
  {
    area: 'RR.HH',
    requerimiento: [
      {
        requerimiento: 'Contrato de trabajo del colaborador afectado en el accidente',
      },
      {
        requerimiento: 'Detalles de lesiones o daños causados a terceros.',
      },
      {
        requerimiento: 'Registros médicos si hubo lesiones, para evaluar la gravedad y causalidad.',
      },
    ],
  },
  {
    area: 'Seguros',
    requerimiento: [
      {
        requerimiento: 'Evaluación de la responsabilidad potencial que le asiste a la empresa.',
      },
      {
        requerimiento: 'Informe de evaluación del riesgo ocurrido, realizado antes del incidente.',
      },
    ],
  },
];
export const AREAS_TRI = ['Área Afectada', 'Prevención de Riesgos', 'Otra Área'];
export const REQUERIMIENTOS_TRI = [
  {
    area: 'Área Afectada',
    requerimiento: [
      {
        requerimiento:
          'Descripción del siniestro, con especial mención de sus causas y consecuencias.',
      },
      {
        requerimiento: 'Informe de causas posibles del siniestro.',
      },
      {
        requerimiento: 'Inventario completo de bienes afectados o perdidos.',
      },
      {
        requerimiento: 'Documentos que acrediten la propiedad del bien afectado.',
      },
      {
        requerimiento: 'Documentación tributaria que respalde los daños y su valoración.',
      },
      {
        requerimiento: 'Estimados o cotizaciones de reparación y/o reemplazo.',
      },
      {
        requerimiento: 'Registros de mantenimiento del bien afectado por el siniestro.',
      },
      {
        requerimiento: 'Permisos y planos de construcción de inmuebles afectados.',
      },
      {
        requerimiento:
          'Fotografías del lugar del incidente y de cualquier daño causado por el siniestro.',
      },
      {
        requerimiento: 'Inventario de contenidos de la propiedad.',
      },
      {
        requerimiento: 'Documentación de mejoras a la propiedad no reflejadas en la póliza.',
      },
      {
        requerimiento: 'Presupuestos detallados de gastos de mitigación de pérdidas.',
      },
      {
        requerimiento: 'Facturas y presupuestos de estimación de reparación de daños.',
      },
    ],
  },
  {
    area: 'Prevención de Riesgos',
    requerimiento: [
      {
        requerimiento: 'Registros de seguridad CCTV que puedan haber registrado el incidente.',
      },
      {
        requerimiento: 'Acciones tomadas para prevenir o mitigar mayores daños.',
      },
      {
        requerimiento: 'Reporte policial, si aplica.',
      },
      {
        requerimiento: 'Reporte de bomberos, si aplica.',
      },
      {
        requerimiento:
          'Reportes internos evacuados para determinar la causa y el alcance del daño.',
      },
      {
        requerimiento: 'Declaraciones de testigos.',
      },
    ],
  },
];

export const categories = [
  'Plazo',
  'Inhabilidades',
  'Oferta técnica',
  'Oferta económica',
  'Oferta de asistencia',
  'Requisitos',
  'Resolución',
  'Otras Consultas',
];

export const questionsAndAnswers = [
  {
    category: 'Plazo',
    data: [
      {
        question: '¿Cuál es la fecha y hora límite para presentar la propuesta?',
        answer:
          'La fecha propuesta en las bases de licitación, y hasta las 12 de la noche. Cada propuesta debe ser enviada vía correo electrónico.',
      },
      {
        question: '¿Se aceptan propuestas fuera de plazo?',
        answer:
          'Por regla general no se aceptan propuestas fuera de plazo. Sin embargo, cada oferente podrá argumentar y defender su retraso por escrito, antes del vencimiento del plazo de entrega de propuestas. Esta petición será evaluada de forma particular y, en caso de ser aceptada, el plazo será ampliado para todos los oferentes.',
      },
    ],
  },
  {
    category: 'Inhabilidades',
    data: [
      {
        question: '¿Podemos participar si ya gestionamos pólizas de su compañía anteriormente?',
        answer: 'No hay restricción por haber gestionado pólizas de nuestra empresa, previamente.',
      },
      {
        question: '¿Hay alguna restricción si aseguramos a uno de sus competidores?',
        answer:
          'No hay restricción por haber gestionado pólizas de nuestra competencia, actualmente. Sin embargo, se deben observar todos los requisitos de confidencialidad de la información entregada, y el oferente deberá notificar a la empresa tan pronto se tome conocimiento.',
      },
    ],
  },
  {
    category: 'Oferta técnica',
    data: [
      {
        question: '¿Pueden proveer más detalles sobre los riesgos a asegurar?',
        answer: 'Se adjunta documento con detalle de activos y riesgos.',
      },
      {
        question: '¿Hay coberturas obligatorias o cláusulas específicas requeridas?',
        answer:
          'Las coberturas obligatorias están definidas en las bases de licitación como cláusulas esenciales.',
      },
      {
        question: '¿Cuáles son los límites mínimos de cobertura solicitados?',
        answer:
          'Los límites mínimos de cobertura estan definidos por la cobertura anterior, y será un factor considerado importante que el oferente proponga una mejora sin afectar la oferta económica, ostensiblemente.',
      },
    ],
  },
  {
    category: 'Oferta económica',
    data: [
      {
        question: '¿Se debe cotizar la prima neta y/o con impuestos?',
        answer: 'Se debe cotizar prima neta sin impuestos.',
      },
      {
        question: '¿Requieren financiamiento de la prima? ¿en cuántas cuotas?',
        answer: 'No se requiere financiamiento.',
      },
    ],
  },
  {
    category: 'Oferta de asistencia',
    data: [
      {
        question: '¿Requieren un ejecutivo de cuenta dedicado?',
        answer: 'No se requiere ejecutivo dedicado.',
      },
      {
        question: '¿Necesitan reportes periódicos de gestión? ¿Con qué frecuencia?',
        answer: 'Se requieren reportes semestrales de gestión.',
      },
    ],
  },
  {
    category: 'Requisitos',
    data: [
      {
        question: '¿Qué documentos debemos presentar junto a la propuesta?',
        answer:
          'Se debe presentar propuesta técnica, económica, y los documentos legales de la corredora, junto con el nombre de la compañía con la que se pretende cotizar.',
      },
      {
        question: '¿Requieren una garantía de seriedad de oferta?',
        answer: 'No se requiere garantía.',
      },
    ],
  },
  {
    category: 'Resolución',
    data: [
      {
        question: '¿Cuándo se espera comunicar la decisión de adjudicación?',
        answer:
          'La decisión de adjudicación será comunicada el mismo día fijado para la resolución de la licitación.',
      },
      {
        question: '¿Cuáles son los criterios de evaluación y ponderación?',
        answer:
          'La ponderación de criterios de evaluación ha sido definida por la empresa esta vez conforme sigue: Oferta técnica 20%, Oferta de servicio 20%, Oferta económica 60%.',
      },
    ],
  },
  {
    category: 'Otras Consultas',
    data: [
      {
        question: '¿Cuándo se espera comunicar la decisión de adjudicación?',
        answer:
          'La decisión de adjudicación será comunicada el mismo día fijado para la resolución de la licitación.',
      },
      {
        question: '¿Cuáles son los criterios de evaluación y ponderación?',
        answer:
          'La ponderación de criterios de evaluación ha sido definida por la empresa esta vez conforme sigue: Oferta técnica 20%, Oferta de servicio 20%, Oferta económica 60%.',
      },
    ],
  },
];
